/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('../css/app.scss');

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
const $ = require('jquery');
// this "modifies" the jquery module: adding behavior to it
// the bootstrap module doesn't exports/return anything
require('bootstrap');

require('@fortawesome/fontawesome-free/css/all.min.css');
require('@fortawesome/fontawesome-free/js/all.js');

// or you can include specific pieces
require('bootstrap/js/dist/tooltip');
// require('bootstrap/js/dist/popover');
require('webpack-jquery-ui');

import Chart from 'chart.js';
import html2canvas from 'html2canvas';

$(document).ready(function() {
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="popover"]').popover();

    //smooth scroll
    $("a[href*='#']:not([href='#'])").click(function() {
        if (
            location.hostname == this.hostname
            && this.pathname.replace(/^\//,"") == location.pathname.replace(/^\//,"")
        ) {
            var anchor = $(this.hash);
            anchor = anchor.length ? anchor : $("[name=" + this.hash.slice(1) +"]");
            if ( anchor.length ) {
                $("html, body").animate( { scrollTop: anchor.offset().top }, 1000);
            }
        }
    });

    //open pop-up aide
    $('#btn-aide').click(function () {
        $('#card-aide').addClass('active');
        $("html, body").animate({scrollTop:0}, 500, 'swing', function() {
        });
    })

    //close pop-up aide
    $('#close-card-aide').click(function () {
        $('#card-aide').removeClass('active');
    })

    //increase chronometre

    var h = parseInt($('#h').html()); // Heure
    var m = parseInt($('#m').html()); // Minute
    var s = parseInt($('#s').html()); // Seconde

    var temps; // Contiendra l'exécution de notre code
    var bo = true; // Permettra de contrôler l'exécution du code

    function dchiffre(nb)
    {
        if(nb < 10) // si le chiffre indiqué est inférieurs à dix ...
        {
            nb = "0"+nb; // .. on ajoute un zéro devant avant affichage
        }

        return nb;
    }

    if(bo) // On controle bo pour savoir si un autre Intervalle est lancé
    {
        temps = setInterval(function()
        {
            s++;

            if(s > 59)
            {
                m++;
                s = 0;
            }

            if(m > 59)
            {
                h++;
                m = 0;
            }

            $("#temps_passe #s").html(dchiffre(s));
            $("#temps_passe #m").html(dchiffre(m));
            $("#temps_passe #h").html(dchiffre(h));

            if(typeof(GLOBAL_TIME) !== 'undefined') {
              GLOBAL_TIME++;
            }
        },1000);

        // On affecte false à bo pour empécher un second Intervalle de se lancer
        bo = false;
    }

    if($('body').hasClass('module-page')) {
      var isLocked = true;
      var linkToLock = null;
      $('a').click(function(e) {
        if(!$(this).hasClass('bypass')) {
          e.preventDefault();
          $('#exit-session-modal').modal('show');

          linkToLock = $(this);
        }
      });

      $('#exit-session-modal [data-action="continue"]').on('click', function() {
        window.location.href = linkToLock.attr('href');
      });

      var moduleService = setInterval(() => {
        $.ajax({
          url: $('form[name=update_session]').attr('action'),
          dataType: 'json',
          method: 'post',
          data: layoutGetFormData($('form[name=update_session]')),
          mimeType:"multipart/form-data",
          success: function(data) {
            //console.log(data.status);
          }
        });
      }, 3000);
    }

    $('[data-action="set-next-val-submit"]').on('click', function() {
      $('[data-change="next-val"]').val($(this).data('question-id'));
      $('form[data-action="update-session"]').submit();
    });

    if(typeof(SHOW_RESUME_MODAL) !== 'undefined') {
      $('#resume-session-modal').modal('show');
    }

    $( "#tag-bag" ).sortable({
      connectWith: "ul",
      placeholder: {
        element: function(currentItem) {
          return '<li class="not-posed"></li>';
        },
        update: function(container, placeholder) {}
      },
      out: function(event, ui ) {
        $(event.target).removeClass('target');
      },
      over: function(event, ui ) {
        $(event.target).addClass('target');
      },
      receive: function(event, ui) {
        $(event.target).addClass('target');

        setTimeout(function() {
          $('ul.droptrue').each(function() {
            if($(this).find('li').length > 0) {
              $(this).addClass('target');
            } else {
              $(this).removeClass('target');
            }
          });
        }, 1);
      }
    }).disableSelection();

    $('.emplacement-etiquette').sortable({
      connectWith: "ul",
      placeholder: {
        element: function(currentItem) {
          return '<li class="not-posed"></li>';
        },
        update: function(container, placeholder) {}
      },
      over: function( event, ui ) {
        $(event.target).addClass('target');
      },
      out: function( event, ui ) {
        $(event.target).removeClass('target');
      },
      receive: function(event, ui) {
        $(event.target).addClass('target');

        setTimeout(function() {
          $('ul.droptrue').each(function() {
            if($(this).find('li').length > 0) {
              $(this).addClass('target');
            }
          });
        }, 1);

        if ($(this).children().length > 1) {
          $(ui.sender).sortable('cancel');
        }
      },
      stop: function(event, ui) {
        setTimeout(function() {
          $('ul.droptrue').each(function() {
            if($(this).find('li').length > 0) {
              $(this).addClass('target');
            } else {
              $(this).removeClass('target');
            }
          });
        }, 1);

        if ($(this).children().length > 1) {
          $(ui.sender).sortable('cancel');
        }
      }
    }).disableSelection();

    $('#form_module_update_session').on('submit', function(e) {
      let isThereEmptyTrou = false;

      $('.emplacement-etiquette').each(function() {
        if($(this).find('li:not(.not-posed)').length == 0) {
          isThereEmptyTrou = true;
        }
      })

      if(isThereEmptyTrou) {
        e.preventDefault();
        e.stopPropagation();
        alert('Vous devez remplir tous les trous du texte pour continuer le module !');
      } else {
        let contenuEtiquettes = '';
        $('.emplacement-etiquette').each(function() {
          contenuEtiquettes += '||'+$(this).find('li:first').html();
        });

        $('input[name="etiquettes_completees"]').val(contenuEtiquettes);
      }
    });


    let showChar = 65;
    let ellipsestext = "...";

    $(".truncate").each(function() {
        let content = $(this).html();
        if (content.length > showChar) {
            let c = content.substr(0, showChar);
            let h = content;
            let html =
                '<div class="truncate-text" style="display:block">' +
                c +
                '<span class="moreellipses">' +
                ellipsestext +
                '&nbsp;<a href="" class="moreless more">Voir plus >></a></span></span></div><div class="truncate-text" style="display:none">' +
                h +
                '&nbsp;<a href="" class="moreless less">Voir moins >></a></span></div>';

            $(this).html(html);
        }
    });

    $(".moreless").click(function() {
        let thisEl = $(this);
        let cT = thisEl.closest(".truncate-text");
        let tX = ".truncate-text";

        if (thisEl.hasClass("less")) {
            cT.prev(tX).toggle();
            cT.slideToggle();
        } else {
            cT.toggle();
            cT.next(tX).fadeToggle();
        }
        return false;
    });

    $('.faq-bloc button[data-toggle="collapse"]').click(function () {
        let bool;
        if($(this).closest('.card').hasClass('active')) {
            bool = true;
        } else {
            bool = false;
        }
        $('.faq-bloc .card').removeClass('active');

        if(bool) {
            $(this).closest('.card').removeClass('active');
        } else {
            $(this).closest('.card').addClass('active');
        }

    });

    $('.echelle-reponses input[type="radio"]:checked').closest('li').addClass('selected-step');

    $('.echelle-reponses input[type="radio"]').change(function() {
      $('.echelle-reponses input[type="radio"]').closest('li').removeClass('selected-step');
      $(this).closest('li').addClass('selected-step');
    });

    if($('#headerContainer').hasClass('active-toggler')) {
      var isAnimatedHeader=false;
      $('#headerContainer').mouseenter(function() {
        if(!isAnimatedHeader) {
          isAnimatedHeader = true;
          $('#toggleHoverMessage').slideUp(100, function() {
            $('#navbarMainContainer').slideDown(100, function() {
                isAnimatedHeader = false;
            });
          });
        }
      }).mouseleave(function() {
        if(!isAnimatedHeader) {
          isAnimatedHeader = true;
          $('#toggleHoverMessage').slideDown(100, function() {
            $('#navbarMainContainer').slideUp(100, function() {
                isAnimatedHeader = false;
            });
          });
        }
      });
    }
});

function layoutGetFormData($form){
  var unindexed_array = $form.serializeArray();
  var indexed_array = {};

  $.map(unindexed_array, function(n, i){
      indexed_array[n['name']] = n['value'];
  });

  return indexed_array;
}

const imagesContext = require.context('../img', true, /\.(png|jpg|jpeg|gif|ico|svg|webp)$/);
imagesContext.keys().forEach(imagesContext);
